import React from 'react';
import './mainNav.scss';
import { Link } from 'react-router-dom';
import { canActivate } from './../../../utils/canActivate';
import { isPhysioterapy } from './../../../utils/isPhysioterapy';

const MainNav = () => {
  return (
    <ul className="nav">
      {
        canActivate('Customers', 'list') ? 
          <li>
            <Link to={`/privado/clientes`}>
              <i className="pe-7s-users"></i>
              <p>Clientes</p>
            </Link>
          </li> : null
      }
      {
        isPhysioterapy() ?
          <li>
            <Link to={`/privado/evolucoes`}>
              <i className="pe-7s-id"></i>
              <p>Evoluções</p>
            </Link>
          </li> : null
      }
      {
        isPhysioterapy() ?
          <li>
            <Link to={`/privado/relatorios`}>
              <i className="pe-7s-note2"></i>
              <p>Relatórios</p>
            </Link>
          </li> : null
      }
      {/* {
        !isPhysioterapy() ?
          <li> 
            <Link to={`/privado/atendimentos`}>
              <i className="pe-7s-id"></i>
              <p>Atendimentos</p>
            </Link>
          </li> : null
      } */}
      {
        !isPhysioterapy() ?
          <li> 
            <Link to={`/producao`}>
              <i className="pe-7s-coffee"></i>
              <p>Produção</p>
            </Link>
          </li> : null
      }
    </ul>
  )
}

export default MainNav;