
import React from 'react';
import axios from 'axios';
import Loading from '../../shared/loading/loading';
import moment from 'moment'

import { isPhysioterapy } from './../../../utils/isPhysioterapy';

class ReportDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: {
        customer_name: '',
        note: '',
        date_time: ''
      },
      isLoading: false
    }
  }

  componentDidMount() {
    if (this.props.match.params.appointmentId) {
      this.getAppointment();
    }
  }

  getAppointment = () => {
    this.setState({ isLoading : true });
    const appointmentId = this.props.match.params.appointmentId;

    const options = {
      method: 'GET',
      url: '/appointments/' + appointmentId
    };
    
    axios(options).then(response => {
      this.setState({ 
        appointment : response.data.data,
        isLoading : false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  createMarkup = () => {
    return {__html: this.state.appointment.note};
  }

  formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  }

  render() {
    return (
      <div>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <h2>Atendimentos</h2>
        <form>
          <div className="row">

            <div className="col-md-6">
              <div className="form-group">
                <label>Cliente</label>
                <div>{this.state.appointment.customer_name}</div>               
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Data</label>
                <div>{this.formatDate(this.state.appointment.date_time)}</div>
              </div>
            </div>

            {
              isPhysioterapy() ? 
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Evolução</label>
                    <div dangerouslySetInnerHTML={this.createMarkup()} />
                  </div>
                </div> :

                <div>Detalhes do atendimento</div>
            }         
          </div>
        </form>
      </div>
    )
  }
}

export default ReportDetail;