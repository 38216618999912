import React from 'react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

const ProductionCard = (props) => {

  const confirmStatusChange = (status, id) => {
    confirmAlert({
      title: 'Você tem certeza?',
      message: 'Você tem certeza que deseja alterar o status deste item?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => props.handleChange(status, id)
        },
        {
          label: 'Não'
        }
      ]
    });
  }

  return (
    <div className="col-6 col-md-4 col-xl-3">
      <div className="ps-card">
        <h4>{ props.service.quantity ? parseInt(props.service.quantity, 0) : '' } { props.service.service_name }</h4>
        <h6>{ moment(props.service.created_at).format('DD/MM/YYYY HH:mm') }</h6>
        {
          props.service.user_name ? <div><strong>Criado por:</strong> { props.service.user_name }</div> : null
        }
        {
          props.service.service_producer ? <div><strong>Produzido por:</strong> { props.service.service_producer }</div> : null
        }
        <div><strong>Cliente:</strong> { props.service.customer_name }</div>
        {
          props.service.is_done ? 
            <button onClick={() => confirmStatusChange(false, props.service.id)} className="btn btn-is-done">Marcar como PENDENTE</button> :
            props.service.is_done === null ? <button onClick={() => confirmStatusChange(true, props.service.id)} className="btn btn-orange">Marcar como FEITO</button> :
            <button onClick={() => confirmStatusChange(true, props.service.id)} className="btn">Marcar como FEITO</button>
        }
      </div>            
    </div>
  );
}

export default ProductionCard;
