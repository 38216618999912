import React from 'react';
import './Customers.scss';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';

import Loading from './../../shared/loading/loading';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isPhysioterapy } from './../../../utils/isPhysioterapy';

class Customers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      activePage: 1,
      totaltCount: 0,
      isLoading: false,
      getEvaluation: false
    }
  }

  componentDidMount() {
    this.getCustomers(1);
  }

  getCustomers = (page) => {
    this.setState({ isLoading: true });
    const options = {
      method: 'POST',
      url: '/customers/list',
      data: {
        quantity: process.env.REACT_APP_PAGE_SIZE,
        page
      },
      json: true
    };
    
    axios(options).then(response => {
      this.setState({
        customers: response.data.data,
        totalCount: response.data.pagination.total_count,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  deleteCustomer = (customerIndex) => {
    this.setState({ isLoading: true });
    const options = {
      method: 'POST',
      url: '/customers/' + this.state.customers[customerIndex].id,
      data: {
        _METHOD: 'DELETE'
      },
      json: true
    };
    
    axios(options).then(response => {
      if (response.status) {
        const _customers = [...this.state.customers];
        _customers.splice(customerIndex, 1);
        if (this._isMounted) {
          this.setState({
            customers : _customers,
            isLoading: false
          })
        }
      }
    }).catch(error => {
      const _error = error.response.data;
      this.setState({ isLoading: false });
      if (!_error.status && _error.code === 1451) {
        confirmAlert({
          title: 'Operação não permitida',
          message: 'Existem evoluções cadastradas para este cliente',
          buttons: [
            {
              label: 'OK'
            }
          ]
        });
      }
    });
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber});
    this.getCustomers(pageNumber);
  }

  confirmDelete = (customerIndex) => {
    confirmAlert({
      title: 'Você tem certeza?',
      message: 'Você tem certeza que deseja excluir o registro? Ele não poderá ser recuperado.',
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.deleteCustomer(customerIndex)
        },
        {
          label: 'Não'
        }
      ]
    });
  }

  getEvaluation = (customerId) => {
    this.setState({ isLoading: true });
    const options = {
      method: 'POST',
      url: 'physiotherapy/evaluation-form/list',
      data: {
        customer_id: customerId
      },
      json: true
    };
    
    axios(options).then(response => {
      if (response.data.data.length) {
        this.props.history.push(`/privado/clientes/${customerId}/avaliacao/${response.data.data[0].id}`)
      } else {
        this.props.history.push(`/privado/clientes/${customerId}/avaliacao/novo`)
      }
    }).catch(error => {
      this.props.history.push(`/privado/clientes/${customerId}/avaliacao/novo`)
      this.setState({ isLoading: false });
    });

  }


  render() {
    return (
      <div className="card">
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="header">
          <h4 className="title">Clientes</h4>
          <p className="category">Lista de clientes ativos no sistema</p>
          <Link to={`/privado/clientes/novo`}><i title="Novo Cliente" className="fa fa-plus"></i> Novo Cliente</Link>
        </div>
        <div className="content table-full-width">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.customers.length ? 
                    this.state.customers.map((customer, i) => {
                      return (
                        <tr key={customer.id}>
                          <td>{customer.name}</td>
                          <td>{customer.email}</td>
                          <td>{customer.phone1}</td>
                          <td className="actions">
                            <i onClick={() => this.confirmDelete(i)} title="Deletar" className="fa fa-trash delete"></i>
                            <Link to={`/privado/clientes/${customer.id}`}><i title="Editar" className="fa fa-edit"></i></Link>
                            {
                              isPhysioterapy() ?
                                <Link to={`/privado/clientes/${customer.id}/avaliacao`}><i title="Ficha de Avaliação" className="fa fa-id-card"></i></Link> : null
                            }
                          </td>
                        </tr>
                      )
                    }) :
                    <tr>
                      <td colSpan={4}>
                        <p>Não existem registros</p>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {
            this.state.customers.length > 0 ? 
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={process.env.REACT_APP_PAGE_SIZE}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              /> : null
          }
        </div>
      </div>
    )
  }
}

export default Customers;