import React from 'react';
import './CustomerRecords.scss';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from 'react-js-pagination';

import Loading from '../shared/loading/loading';

class CustomerRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      activePage: 1,
      totalCount: 0,
      newAppointment: false,
      isLoading: false
    }
  }

  componentDidMount() {
    this.getAppointments(1);
  }

  getAppointments = (page) => {
    this.setState({ isLoading: true });
    const options = {
      method: 'POST',
      url: '/appointments/list',
      data: {
        quantity: process.env.REACT_APP_PAGE_SIZE,
        page
      },
      json: true
    };
    
    axios(options).then(response => {
      this.setState({ 
        appointments : response.data.data,
        totalCount: response.data.pagination.total_count,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber});
    this.getAppointments(pageNumber);
  }

  deleteService = (serviceId) => {
    const options = {
      method: 'POST',
      url: '/performed-services/' + serviceId,
      data: {
        _METHOD: 'DELETE'
      }
    };
    
    axios(options).then(() => {
    }).catch(error => {
      const _error = error.response.data;
      console.log(_error);
      this.setState({ isLoading: false });
    });
  }

  getAppointmentService = (appointmentId) => {
    const options = {
      method: 'POST',
      url: '/performed-services/list',
      data: {
        appointment_id: appointmentId
      }
    };
    
    axios(options).then(response => {
      if (response.data.data) {
        const services = response.data.data;
        services.map((service, index) => {
          this.deleteService(service.id);
          services.splice(index, 1);
          if (!services.length) {
            this.deleteAppointment(appointmentId);
          }
          return true;
        });
      }
    }).catch(error => {
      this.deleteAppointment(appointmentId);
    });
  }

  deleteAppointment = (appointmentId) => {
    this.setState({ isLoading: true });
    
    const options = {
      method: 'POST',
      url: '/appointments/' + appointmentId,
      data: {
        _METHOD: 'DELETE'
      }
    };
    
    axios(options).then(response => {
      if (response.status) {
        const _appointments = [...this.state.appointments];
        const appointmentIndex = _appointments.find(a => a.id === appointmentId);
        _appointments.splice(appointmentIndex, 1);
        this.setState({ 
          appointments : _appointments,
          isLoading: false
        });
      }
    }).catch(error => {
      const _error = error.response.data;
      console.log(_error);
      this.setState({ isLoading: false });
    });

  }

  confirmDelete = (appointmentId) => {
    confirmAlert({
      title: 'Você tem certeza?',
      message: 'Você tem certeza que deseja excluir o registro? Ele não poderá ser recuperado.',
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.getAppointmentService(appointmentId)
        },
        {
          label: 'Não'
        }
      ]
    });
  }

  render() {
    if(this.state.newAppointment) {
      return (
        <Redirect to='/privado/evolucoes/novo' />
      )
    }
    return (
      <div className="card">
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="header">
          <h4 className="title">Evoluções</h4>
          <p className="category">Lista de atendimentos</p>
          <Link to={`/privado/evolucoes/novo`}><i title="Nova Evolução" className="fa fa-plus"></i> Nova Evolução</Link>
        </div>
        <div className="content table-full-width">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Cliente</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.appointments.length ?
                    this.state.appointments.map((appointment, i) => {
                      return (
                        <tr key={appointment.id}>
                          <td>{moment(appointment.date_time).format('DD/MM/YYYY')}</td>
                          <td>{appointment.customer_name}</td>
                          <td className="actions">
                            <i onClick={() => this.confirmDelete(appointment.id)} title="Deletar" className="fa fa-trash"></i>
                            <Link to={`/privado/evolucoes/${appointment.id}`}><i title="Editar" className="fa fa-edit"></i></Link>
                          </td>
                        </tr>
                      )
                    }) :
                    <tr>
                      <td colSpan={4}>
                        <p>Não existem registros</p>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {
            this.state.appointments.length > 0 ? 
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={process.env.REACT_APP_PAGE_SIZE}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              /> : null
          }
        </div>
      </div>
    )
  }
}

export default CustomerRecords;