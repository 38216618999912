import React from 'react';
import './sidebar.scss';
import Brand from './../brand/brand';
import MainNav from './../mainNav/mainNav';

const SideBar = () => {
  return (
    <div className="sidebar" data-color="black" data-image="./../../assets/images/background.jpg">
      <div className="sidebar-background"></div>

      <Brand />

      <div className="sidebar-wrapper">
        <MainNav />        
      </div>
    
    </div>
  )
}

export default SideBar;