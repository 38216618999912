
let store = require('store');

export const canActivate = (moduleName, action) => {
  const permissions = store.get('permissions');
  if (permissions && permissions.length) {
    const moduleObj = permissions.filter(element => element.module_name === moduleName);
    if (moduleObj.length) {
      return moduleObj[0][action];
    } else {
      return false;
    }
  } else {
    return false;
  }
}