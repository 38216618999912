import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.scss';

import Sidebar from '../shared/sidebar/sidebar';
import Customers from '../Customers/components/Customers';
import CustomerDetail from '../Customers/components/CustomerDetail';
import Navbar from '../shared/navbar/navbar';
import Appointments from '../Appointments/Appointments';
import AppointmentDetail from '../Appointments/AppointmentDetail';
import Reports from '../Reports/components/Reports';
import ReportDetail from '../Reports/components/ReportDetail';
import CustomerRecords from '../CustomerRecords/CustomerRecords';
import CustomerRecordsDetail from '../CustomerRecords/CustomerRecordsDetail';
import EvaluationDetail from '../Evaluations/components/EvaluationDetail';

function App() {
  return (
    <div className="wrapper">
      <Sidebar />

      <div className="main-panel">

        <Navbar />

        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Switch>
                  <Route exact path="/privado/clientes" component={Customers} />
                  <Route path="/privado/clientes/novo" component={CustomerDetail} />
                  <Route exact path="/privado/clientes/:customerId" component={CustomerDetail} />

                  <Route exact path="/privado/clientes/:customerId/avaliacao" component={EvaluationDetail} />

                  <Route exact path="/privado/atendimentos" component={Appointments} />
                  <Route path="/privado/atendimentos/novo" component={AppointmentDetail} />
                  <Route path="/privado/atendimentos/:appointmentId" component={AppointmentDetail} />

                  <Route exact path="/privado/evolucoes" component={CustomerRecords} />
                  <Route path="/privado/evolucoes/novo" component={CustomerRecordsDetail} />
                  <Route path="/privado/evolucoes/:appointmentId" component={CustomerRecordsDetail} />
                  
                  <Route exact path="/privado/relatorios" component={Reports} />
                  <Route path="/privado/relatorios/:appointmentId" component={ReportDetail} />
                </Switch>
                <Redirect exact from="/" to="/privado/clientes" />
              </div>
            </div>
          </div>        
        </div>
      </div>
    </div>
  );
}

export default App;
