import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import moment from 'moment'

import Loading from '../shared/loading/loading';
import SubmitButton from '../shared/submitButton/submitButton';
import ErrorMessage from '../shared/errorMessage/errorMessage';

registerLocale('pt', pt);

class CustomerRecordsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: {
        customer_id: '',
        note: '',
        date_time: ''
      },
      customers: [],
      services: [],
      selected_date: '',
      updated: false,
      isLoading: false,
      invalid: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.appointmentId) {
      this.getAppointment();
    }
    this.getCustomers();
    this.getServices();
  }

  getAppointment = () => {
    this.setState({ isLoading : true });
    const appointmentId = this.props.match.params.appointmentId;

    const options = {
      method: 'GET',
      url: '/appointments/' + appointmentId
    };
    
    axios(options).then(response => {
      this.setState({ 
        selected_date: new Date(response.data.data.date_time),
        appointment : response.data.data,
        isLoading : false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  getCustomers = () => {

    const options = {
      method: 'POST',
      url: '/customers/list',
      data: {
        application_id: 3
      }
    };
    
    axios(options).then(response => {
      this.setState({ customers : response.data.data });
    }).catch(error => console.log(error));
  }

  getServices = () => {

    const options = {
      method: 'POST',
      url: '/services/list',
      data: {
        application_id: 3
      }
    };
    
    axios(options).then(response => {
      this.setState({ services : response.data.data });
    }).catch(error => console.log(error));

  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const { customer_id, note, date_time } = this.state.appointment;
    if (!customer_id || !note || !date_time) {
      this.setState({
        invalid: true,
        isLoading: false
      });
      return;
    }

    const value = this.state.appointment;
    let url = '/appointments';
    
    if (this.state.appointment.id) {
      const appointmendId = value['id'];
      url = `/appointments/${appointmendId}`;
      value['_METHOD'] = 'PUT';
      delete value['created_at'];
      delete value['updated_at'];
      delete value['id'];
    } else {
      value['total_value'] = 0;
      value['final_value'] = 0;
      value['active'] = true;
    }
    // eslint-disable-next-line
    value['date_time'] = moment(value['date_time']).format('YYYY-MM-DD\Thh:mm:ss');
        
    const options = {
      method: 'POST',
      url: url,
      data: value
    };

    axios(options)
      .then(response => {
        if (this.state.services.length && response.data.id) {
          this.createAppointmentService(response.data.id);
        } else {
          this.setState({
            updated: true,
            isLoading: false
          });
        }
      })
    .catch(error => {
      console.log('error:' + error);
      this.setState({ isLoading: false });
    })
  }

  createAppointmentService = (appointmentId) => {
    const options = {
      method: 'POST',
      url: '/performed-services',
      data: {
        value: '1.00',
        service_id: this.state.services[0].id,
        appointment_id: appointmentId
      }
    };

    axios(options)
      .then(() => {
        this.setState({
          updated: true,
          isLoading: false
        });
      })
    .catch(error => {
      console.log('error:' + error);
      this.setState({ isLoading: false });
    })
  }

  handleDateChange = (date) => {
    this.setState({ 'selected_date': new Date(date) });
    this.setAppointmentData('date_time', date);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setAppointmentData(name, value);
  }

  setAppointmentData(name, value) {
    this.setState(prevState => ({
      appointment: {
        ...prevState.appointment,
        [name]: value
      }
    }));
  }

  render() {
    if(this.state.updated) {
      return <Redirect to='/privado/evolucoes' />
    }
    return (
      <div>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <h2>Cadastro de Evolução</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="row">

            <div className="col-md-6">
              <div className="form-group">
                <label>Cliente</label>
                <select name="customer_id" className="form-control" value={this.state.appointment.customer_id} onChange={this.handleInputChange}>
                  <option value="">Selecione o cliente</option>
                  {
                    this.state.customers.map(customer => {
                      return (
                        <option key={customer.id} value={customer.id}>{customer.name} - tel.: {customer.phone1}</option>
                      )
                    })
                  }
                </select>
                {
                  (!this.state.appointment.customer_id && this.state.invalid) ?
                    <ErrorMessage message={'Selecione o cliente'} /> : null
                }                
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Data</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={this.state.selected_date}
                  onSelect={this.handleDateChange}
                  locale="pt"
                  format="Pp"
                />
                {
                  (!this.state.appointment.date_time && this.state.invalid) ?
                    <ErrorMessage message={'Preencha a data'} /> : null
                }
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Evolução</label>
                <CKEditor
                    editor={ ClassicEditor }
                    data={ this.state.appointment.note }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.setAppointmentData('note', data);
                      } 
                    }
                    config={ {
                        removePlugins: [ 'ImageUpload', 'MediaEmbed' ]
                      }
                    }
                />
                {
                  (!this.state.appointment.note && this.state.invalid) ?
                    <ErrorMessage message={'Informe a evolução'} /> : null
                }
              </div>
            </div>

            <div className="ml-auto col-auto mt-3">
              <div className="form-group">
                <SubmitButton />
              </div>
            </div>
            
          </div>
        </form>
      </div>
    )
  }
}

export default CustomerRecordsDetail;