import React from 'react';

import './Login.scss';
import LoginForm from './LoginForm';
import ForgetForm from './ForgetForm';
import ResetForm from './ResetForm';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 'isLogin',
      token: null
    }
  }

  componentDidMount = () => {
    this.checkPath();
  }

  checkPath = () => {
    const pathChunks = this.props.location.pathname.split("/");
    if (pathChunks[1] === 'redefinir-senha') {
      this.setState({
        formType: 'isReset',
        token: pathChunks[2]
      })
    }
  }

  setForgetForm = () => {
    this.setState({
      formType: 'isForget'
    })
  }

  backToLogin = () => {
    this.setState({
      formType: 'isLogin'
    })
  }

  render() {
    return (
      <div>
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" data-color="black" data-image="./assets/images/background.jpg">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
  
                    <div className="card">
                      {
                        this.state.formType === 'isLogin' ?
                          <div>
                            <LoginForm />
                            <div className="forget-password-link" onClick={this.setForgetForm} >Esqueci minha senha</div>
                          </div> : this.state.formType === 'isForget' ? 
                            <div><ForgetForm backToLogin={this.backToLogin} /></div> : 
                            <div><ResetForm token={this.state.token} /></div>
                      }
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer footer-transparent"></footer>
            <div className="full-page-background"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
