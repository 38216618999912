import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import moment from 'moment'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import Loading from './../../shared/loading/loading';

import ErrorMessage from '../../shared/errorMessage/errorMessage';
import SubmitButton from './../../shared/submitButton/submitButton';

registerLocale('pt', pt);

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        name: '',
        email: '',
        phone1: '',
        gender: '',
        birthdate: ''
      },
      updated: false,
      invalid: false,
      birthDate: '',
      isLoading: true
    }

  }

  componentDidMount() {
    this.setState({isLoading: false});
    if (this.props.match.params.customerId) {
      this.getCustomer();
    }
  }

  getCustomer = () => {
    this.setState({ isLoading: true });
    const customerId = this.props.match.params.customerId;

    const options = {
      method: 'GET',
      url: '/customers/' + customerId
    };
    
    axios(options).then(response => {
      response.data.data.phone1 = response.data.data.phone1 ? response.data.data.phone1.replace('+55 ', '') : ''; 
      this.setState({
        customer : response.data.data,
        birthdate: new Date(response.data.data.birthdate),
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ isLoading: false });
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    if(!this.state.customer.name || !this.state.customer.birthdate) {
      this.setState({
        invalid: true,
        isLoading: false
      });
      return;
    }

    const value = Object.assign({}, this.state.customer);
    let url = '/customers';

    if (this.props.match.params.customerId) {
      const customerId = this.props.match.params.customerId;
      url += `/${customerId}`; 
      value['_METHOD'] = 'PUT';

      value['phone1'] = '+55 '.concat(value['phone1']);

      delete value['created_at'];
      delete value['updated_at'];
      delete value['id'];
    } else {
      value['active'] = true;
      if (value['phone1']) {
        value['phone1'] = '+55 '.concat(value['phone1']);
      }
    }

    // eslint-disable-next-line
    value['birthdate'] = moment(value.birthdate).format('YYYY-MM-DD\Thh:mm:ss');
    
    const options = {
      method: 'POST',
      url: process.env.REACT_APP_API_URL + url,
      data: value
    };

    axios(options)
      .then(response => {
        this.setState({
          updated: true,
          isLoading: false
        });
      })
    .catch(error => {
      console.log('error:' + error);
      this.setState({ isLoading: false });
    })
  }

  handleDateChange = (date) => {
    this.setState({'birthdate': date});
    this.setCustomerData('birthdate', date);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setCustomerData(name, value);

  }

  setCustomerData = (name, value) => {
    this.setState(prevState => ({
      customer: {
        ...prevState.customer,
        [name]: value
      }
    }));
  }


  render() {
    if(this.state.updated) {
      return <Redirect to='/privado/clientes' />
    }
    return (
      <div>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <h2>Cadastro do Cliente</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Nome</label>
                <input className="form-control" type="text" value={this.state.customer.name} onChange={this.handleInputChange} name="name"></input>
                {
                  (this.state.invalid && !this.state.customer.name) ?
                    <ErrorMessage message={'Informe o nome do cliente'} /> : null
                }
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <input className="form-control" type="text" value={this.state.customer.email || ''} onChange={this.handleInputChange} name="email"></input>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Telefone</label>
                <input className="form-control" maxLength="11" type="tel" value={this.state.customer.phone1} onChange={this.handleInputChange} name="phone1"></input>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Gênero</label>
                <select name="gender" className="form-control" value={this.state.customer.gender || ''} onChange={this.handleInputChange}>
                  <option value="">Selecione o gênero</option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Data de Nascimento</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={this.state.birthdate}
                  onSelect={this.handleDateChange}
                  locale="pt"
                  format="Pp"
                />
                {
                  (this.state.invalid && !this.state.customer.birthday) ?
                    <ErrorMessage message={'Informe o data de nascimento'} /> : null
                }
              </div>
            </div>

            <div className="ml-auto col-auto mt-3">
              <div className="form-group">
                <SubmitButton />
              </div>
            </div>
            
          </div>
        </form>
        
      </div>
    )
  }
}

export default CustomerDetail;