import * as firebase from 'firebase/app';
import 'firebase/messaging';

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD0W2yItzPMPLJL8iYOV855xfGfBRed8ls",
  authDomain: "finappbr.firebaseapp.com",
  databaseURL: "https://finappbr.firebaseio.com",
  projectId: "finappbr",
  storageBucket: "",
  messagingSenderId: "800211024213",
  appId: "1:800211024213:web:f36fc5a7cb8bf38784d7dc"
});

let messaging;

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    'BBdqR6nyfxHvnR0Luymg4RdZwc5mayjeIM3A6MZdSm5HeSNWX_MQeW-sLNFKkA6P4xC7FVb6n5-JwYrcCT4WKXU'
  );
}


export { messaging };