import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Pagination from 'react-js-pagination';

import Loading from './../../shared/loading/loading';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      activePage: 1,
      totalCount: 0,
      isLoading: false,
      customers: [],
      
      customer_id: '',
      period: ''
    }
  }

  componentDidMount() {
    this.getAppointments(1);
    this.getCustomers();
  }

  getAppointments = (page) => {
    this.setState({ isLoading: true });

    const data = {
      quantity: process.env.REACT_APP_PAGE_SIZE,
      page
    }

    if (this.state.customer_id) data['customer_id'] = this.state.customer_id;
    if (this.state.period) data['period'] = this.state.period;

    const options = {
      method: 'POST',
      url: '/appointments/list',
      data,
      json: true
    };
    
    axios(options).then(response => {
      this.setState({ 
        appointments : response.data.data,
        totalCount: response.data.pagination.total_count,
        isLoading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({ 
        appointments : [],
        totalCount: 0,
        isLoading: false
      });
    });
  }

  getCustomers = () => {

    const options = {
      method: 'POST',
      url: '/customers/list',
      data: {
        application_id: 3
      }
    };
    
    axios(options).then(response => {
      this.setState({ customers : response.data.data });
    }).catch(error => console.log(error));
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber});
    this.getAppointments(pageNumber);
  }

  handleSelect = (name, event) => {
    this.setState({
        [name]: event.target.value
    }, () => this.getAppointments(1));    
  }



  render() {
    return (
      <div className="card">
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="header">
          <h4 className="title">Relatórios</h4>
          <p className="category">Lista de atendimentos</p>
        </div>
        <div className="filters">
          <h5>Filtros</h5>
          <div className="row">
            <div className="col-6">
              <select value={this.state.customer_id} onChange={(event) => this.handleSelect('customer_id', event)}>
                <option value="" disabled>Selecione um cliente</option>
                <option value="">TODOS OS CLIENTES</option>
                {
                  this.state.customers.map(customer => {
                    return (
                      <option key={customer.id} value={customer.id}>{customer.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="col-6">
              <select value={this.state.period}  onChange={(event) => this.handleSelect('period', event)}>
                <option value="" disabled>Selecione um período</option>
                <option value="1">Hoje</option>
                <option value="2">Desde Ontem</option>
                <option value="3">Últimos 7 dias</option>
                <option value="4">Últimos 30 dias</option>
                <option value="8">Semana Passada</option>
                <option value="9">Mês Passado</option>
                <option value="10">3 Meses Passados</option>
                <option value="11">6 Meses Passados</option>
                <option value="">SEMPRE</option>
              </select>
            </div>
          </div>
        </div>
        <div className="content table-full-width">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Cliente</th>
                  {
                    
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.appointments.length ?
                    this.state.appointments.map((appointment, i) => {
                      return (
                        <tr key={appointment.id}>
                          <td>{moment(appointment.date_time).format('DD/MM/YYYY')}</td>
                          <td>{appointment.customer_name}</td>
                          <td className="actions">
                            <Link to={`/privado/relatorios/${appointment.id}`}><i title="Visualizar" className="fa fa-eye"></i></Link>
                          </td>
                        </tr>
                      )
                    }) :
                    <tr>
                      <td colSpan={4}>
                        <p>Não existem registros</p>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {
            this.state.appointments.length > 0 ? 
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={process.env.REACT_APP_PAGE_SIZE}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              /> : null
          }
        </div>
      </div>
    )
  }
}

export default Reports;