import React from 'react';
import { Redirect } from 'react-router-dom';
import './navbar.scss';
import axios from 'axios';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedOut: false,
      userName: localStorage.getItem('userName')
    }
  }

  logout = () => {
    const options = {
      method: 'POST',
      url: '/auth/logout',
      data: {
        device_name: 'desktop'
      }
    };

    axios(options)
      .then(() => {
        localStorage.removeItem('token');
        this.setState({ isLoggedOut: true });
      })
    .catch(error => {
      localStorage.removeItem('token');
      this.setState({ isLoggedOut: true });
      console.log('error:' + error);
    })

  }

  render() {
    if (this.state.isLoggedOut) {
      return <Redirect to='/login' />
    }

    return (
      <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-header">
            {/* <span className="user-button">
              <i className="pe-7s-user"></i><span>Meu perfil</span>
            </span> */}
            {
              this.state.userName ? <span className="user">Olá, { this.state.userName.replace(/["']/g, '') }</span> : null
            }
            <button className="btn-wd btn btn-default" onClick={this.logout}>
              <i className="pe-7s-power"></i><span>Sair</span>
            </button>
          </div>
        </div>
      </nav>
    )

  }

}

export default NavBar;