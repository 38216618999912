import React from 'react';
import 'babel-polyfill';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';

import './index.scss';

import Routes from './utils/routes';
import axios from 'axios';

require('dotenv').config();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = "application/json";
if (localStorage.getItem('token')) {
  axios.defaults.headers.common['Authorization'] = 'auth ' + localStorage.getItem('token').replace(/['"]+/g, '');
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
  <Routes />
  , document.getElementById('root')
);

