import React from 'react';
import './Login.scss';
import axios from 'axios';
import ErrorMessage from '../shared/errorMessage/errorMessage';
import { Redirect } from 'react-router-dom';
import Loading from '../shared/loading/loading';

let store = require('store');


class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isAuthenticated: false,
      isLoading: false,
      hasError: false
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      hasError: false
    });


    const value = this.state;
    value['device_name'] = 'desktop';
    delete value['isAuthenticated'];
    delete value['isLoading'];
    const options = {
      method: 'POST',
      url: '/auth/login',
      data: value,
      json: true
    };

    axios(options)
      .then(response => {
        store.set('token', response.data.token);
        store.set('permissions', response.data.permission);
        store.set('userName', response.data.user_name);
        axios.defaults.headers.common['Authorization'] = 'auth ' + localStorage.getItem('token').replace(/['"]+/g, '');
        this.setState({
          isAuthenticated: true,
          isLoading: false
        });
      })
      .catch(error => {
        console.log('error:' + error);
        this.setState({
          isLoading: false,
          hasError: true
        });
      })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render() {
    if(this.state.isAuthenticated) {
      return <Redirect to='/' />
    }
    const { email, password } = this.state;
    const enabled = email.length > 0 && password.length > 0;
    return (
      <form onSubmit={this.handleSubmit}>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="header text-center">
          <h4 className="title">Login</h4>
        </div>
        {
          this.state.hasError ? <div className="card-has-error"><ErrorMessage message="Dados inválidos." /></div> : null
        }
        <div className="form-group">
          <label>Email</label>
          <input className="form-control" placeholder="Digite o email" type="text" name="email" value={this.state.email} onChange={this.handleInputChange}></input>
        </div>
        <div className="form-group">
          <label>Senha</label>
          <input className="form-control" placeholder="Digite a senha" type="password" name="password" value={this.state.password} onChange={this.handleInputChange}></input>
        </div>
        <button className="btn btn-info btn-wd" disabled={!enabled} type="submit">Entrar</button>
      </form>
    )
  }
}

export default LoginForm;