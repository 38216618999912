
import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import moment from 'moment'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import Loading from './../../shared/loading/loading';

import ErrorMessage from '../../shared/errorMessage/errorMessage';
import SubmitButton from '../../shared/submitButton/submitButton';

registerLocale('pt', pt);


class EvaluationDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: false,
      invalid: false,
      isLoading: false,
      evaluation: {},
      evaluationDate: '',
      isReady: false
    }
  }

  componentDidMount = () => {
    if (this.props.match.params.customerId) {
      this.getEvaluationDetail(this.props.match.params.customerId);
    }
  }

  getEvaluationDetail = (customerId) => {

    this.setState({ isLoading: true });
    const options = {
      method: 'GET',
      url: `physiotherapy/evaluation-form/${customerId}`,
      json: true
    };
    
    axios(options).then(response => {
      response.data.data.phone1 = response.data.data.phone1 ? response.data.data.phone1.replace('+55 ', '') : ''; 
      this.setState({
        evaluation: response.data.data,
        evaluationDate: response.data.data.data_avaliacao ? new Date(response.data.data.data_avaliacao) : new Date(),
        isLoading: false,
        isReady: true
      })
    }).catch(error => {
      this.setState({ isLoading: false });
    });

  }

  handleDateChange = (date) => {
    this.setEvaluationData('data_avaliacao', date);
  }

  handleCheckboxChange = (control, value) => {
    this.setState(prevState => ({
      evaluation: {
        ...prevState.evaluation,
        [control]: !value
      }
    }));
            
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setEvaluationData(name, value);
  }

  setEvaluationData = (name, value) => {
    this.setState(prevState => ({
      evaluation: {
        ...prevState.evaluation,
        [name]: value
      }
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let value = {
      active: true
    };

    Object.keys(this.state.evaluation).map(element => {
      if (this.state.evaluation[element] !== null && !element.match(/customer_/g) && element !== 'phone1') {
        value[element] = this.state.evaluation[element]
      }
    });

    let url = '/physiotherapy/evaluation-form';

    if (value.id) {
      value['_METHOD'] = 'PUT';
      url = '/physiotherapy/evaluation-form/' + value.id;
    } else {
      value['customer_id'] = parseInt(this.props.match.params.customerId);
      value['data_avaliacao'] = moment(this.evaluationDate).format('YYYY-MM-DD\Thh:mm:ss')
    }

    const options = {
      method: 'POST',
      url,
      data: value
    };

    axios(options)
      .then(response => {
        this.setState({
          updated: true,
          isLoading: false
        });
      })
    .catch(error => {
      console.log('error:' + error);
      this.setState({ isLoading: false });
    })
    
  }

  render() {
    if(this.state.updated) {
      return <Redirect to={`/privado/clientes`} />
    }
    return (
      <div>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <h2>Ficha de Avaliação</h2>
        {
          this.state.isReady ? 
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Data da Avaliação</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={this.state.evaluationDate}
                    onSelect={this.handleDateChange}
                    locale="pt"
                    format="Pp"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                <h4>Informações Pessoais</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Nome</label>
                  <input className="form-control" disabled type="text" value={this.state.evaluation.customer_name} name="customer_name"></input>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Telefone para contato</label>
                  <input className="form-control" disabled type="text" value={this.state.evaluation.customer_phone1} name="customer_phone1"></input>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Endereço</label>
                  <input className="form-control" type="text" value={this.state.evaluation.endereco || ''} onChange={this.handleInputChange} name="endereco"></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Data de Nascimento</label>
                  <input className="form-control" disabled type="text" value={moment(this.state.evaluation.customer_birthdate).format('DD/MM/YYYY')} name="customer_birthdate"></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Idade</label>
                  <input className="form-control" disabled type="text" value={this.state.evaluation.customer_age} name="customer_age"></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Profissão</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.profissao || ''} name="profissao"></input>
                </div>
              </div>             
            </div>
            
            <div className="row">
              <div className="col-12 mt-3">
                <h4>Informações médicas</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Indicação médica?</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.indicacao_medica || ''} name="indicacao_medica">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Motivo</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.motivo || ''} name="motivo"></input>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Nome do Médico</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.nome_medico || ''} name="nome_medico"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Diagnóstico</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.diagnostico || ''} name="diagnostico"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Queixa Principal</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.queixa_principal || ''} name="queixa_principal"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Exame</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.exame || ''} name="exame"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>HDA</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.hda || ''} name="hda"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Dores</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.dor || ''} name="dor"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Outras Doenças ou Alterações</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.outra_doenca_alteracao || ''} name="outra_doenca_alteracao"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Cirurgias</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.cirurgia || ''} name="cirurgia"></input>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Fumante?</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.fumante || ''} name="fumante">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Bebida Alcoólica?</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.ingere_bebida_alcoolica || ''} name="ingere_bebida_alcoolica">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Pratica Atividade Física?</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.pratica_atividade_fisica || ''} name="pratica_atividade_fisica">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Está trabalhando?</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.trabalha || ''} name="trabalha">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Antecedentes Familiares?</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.antecedente_familiar || ''} name="antecedente_familiar">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Grau de Parentesco:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.grau_parentesco || ''} name="grau_parentesco"></input>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>DMS</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.dms || ''} name="dms">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>HAS</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.has || ''} name="has">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Cardipatia</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.cardiopatia || ''} name="cardiopatia">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>CA</label>
                  <select className="form-control" onChange={this.handleInputChange} value={this.state.evaluation.ca || ''} name="ca">
                    <option disabled value="">Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-12 mt-3">
                <h4>Avaliação Física</h4>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Encurtamento de Isquiotibiais/Lombares/Paravertebrais:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.encurtamento || ''} name="encurtamento"></input>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Comprimento de MMII:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.comprimento_mmii || ''} name="comprimento_mmii"></input>
                </div>
              </div>

              <div className="col-12 mt-3">
                <h5>Cervical</h5>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Flexão:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.cervical_flexao || ''} name="cervical_flexao"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Extensão:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.cervical_extensao || ''} name="cervical_extensao"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Inclinação:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.cervical_inclinacao || ''} name="cervical_inclinacao"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Rotação:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.cervical_rotacao || ''} name="cervical_rotacao"></input>
                </div>
              </div>

              <div className="col-12 mt-3">
                <h5>Tronco</h5>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Flexão Máxima:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.tronco_flexao_maxima || ''} name="tronco_flexao_maxima"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Gibosidade:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.tronco_gibosidade || ''} name="tronco_gibosidade"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Medida da Distância Mão / Solo:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.tronco_medida_distancia_mao_solo || ''} name="tronco_medida_distancia_mao_solo"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Extensão Máxima:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.tronco_extensao_maxima || ''} name="tronco_extensao_maxima"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Inclinação Máxima:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.tronco_inclinacao_maxima || ''} name="tronco_inclinacao_maxima"></input>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>Rotação Máxima:</label>
                  <input className="form-control" type="text" onChange={this.handleInputChange} value={this.state.evaluation.tronco_rotacao_maxima || ''} name="tronco_rotacao_maxima"></input>
                </div>
              </div>

              <div className="col-12 mt-3">
                <h4>Avaliação Postural</h4>
              </div>

              <div className="col-12 mt-3">
                <h5>Vista Anterior</h5>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Hálux:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('halux_alinhado', this.state.evaluation.halux_alinhado)} defaultChecked={this.state.evaluation.halux_alinhado}></input>
                     Alinhado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('halux_valgo_d', this.state.evaluation.halux_valgo_d)} defaultChecked={this.state.evaluation.halux_valgo_d}></input>
                     Valgo Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('halux_valgo_e', this.state.evaluation.halux_valgo_e)} defaultChecked={this.state.evaluation.halux_valgo_e}></input>
                     Valgo Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Antepé:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('antepe_alinhado', this.state.evaluation.antepe_alinhado)} defaultChecked={this.state.evaluation.antepe_alinhado}></input>
                     Alinhado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('antepe_aduzido_d', this.state.evaluation.antepe_aduzido_d)} defaultChecked={this.state.evaluation.antepe_aduzido_d}></input>
                     Aduzido Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('antepe_aduzido_e', this.state.evaluation.antepe_aduzido_e)} defaultChecked={this.state.evaluation.antepe_aduzido_e}></input>
                     Aduzido Esquerda
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('antepe_abduzido_d', this.state.evaluation.antepe_abduzido_d)} defaultChecked={this.state.evaluation.antepe_abduzido_d}></input>
                     Abduzido Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('antepe_abduzido_e', this.state.evaluation.antepe_abduzido_e)} defaultChecked={this.state.evaluation.antepe_abduzido_e}></input>
                     Abduzido Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Arco Longitudinal Medial:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('arco_longitudinal_medial_plano_d', this.state.evaluation.arco_longitudinal_medial_plano_d)} defaultChecked={this.state.evaluation.arco_longitudinal_medial_plano_d}></input>
                     Plano Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('arco_longitudinal_medial_plano_e', this.state.evaluation.arco_longitudinal_medial_plano_e)} defaultChecked={this.state.evaluation.arco_longitudinal_medial_plano_e}></input>
                     Plano Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('arco_longitudinal_medial_cavo_d', this.state.evaluation.arco_longitudinal_medial_cavo_d)} defaultChecked={this.state.evaluation.arco_longitudinal_medial_cavo_d}></input>
                     Cavo Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('arco_longitudinal_medial_cavo_e', this.state.evaluation.arco_longitudinal_medial_cavo_e)} defaultChecked={this.state.evaluation.arco_longitudinal_medial_cavo_e}></input>
                     Cavo Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Apoio do Antepé:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('apoio_antepe_borda_medial_lateral', this.state.evaluation.apoio_antepe_borda_medial_lateral)} defaultChecked={this.state.evaluation.apoio_antepe_borda_medial_lateral}></input>
                     Apoio Borda Medial e Lateral
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('apoio_antepe_maior_medial_d', this.state.evaluation.apoio_antepe_maior_medial_d)} defaultChecked={this.state.evaluation.apoio_antepe_maior_medial_d}></input>
                     Maior Apoio Medial Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('apoio_antepe_maior_medial_e', this.state.evaluation.apoio_antepe_maior_medial_e)} defaultChecked={this.state.evaluation.apoio_antepe_maior_medial_e}></input>
                     Maior Apoio Medial Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('apoio_antepe_maior_lateral_d', this.state.evaluation.apoio_antepe_maior_lateral_d)} defaultChecked={this.state.evaluation.apoio_antepe_maior_lateral_d}></input>
                     Maior Apoio Lateral Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('apoio_antepe_maior_lateral_e', this.state.evaluation.apoio_antepe_maior_lateral_e)} defaultChecked={this.state.evaluation.apoio_antepe_maior_lateral_e}></input>
                     Maior Apoio Lateral Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Articulação do Joelho:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_alinhada_va', this.state.evaluation.articulacao_joelho_alinhada_va)} defaultChecked={this.state.evaluation.articulacao_joelho_alinhada_va}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_valgo', this.state.evaluation.articulacao_joelho_valgo)} defaultChecked={this.state.evaluation.articulacao_joelho_valgo}></input>
                     Valgo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_varo', this.state.evaluation.articulacao_joelho_varo)} defaultChecked={this.state.evaluation.articulacao_joelho_varo}></input>
                     Varo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Patelas:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('patela_alinhada', this.state.evaluation.patela_alinhada)} defaultChecked={this.state.evaluation.patela_alinhada}></input>
                     Alinhadas
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('patela_mais_alta_d', this.state.evaluation.patela_mais_alta_d)} defaultChecked={this.state.evaluation.patela_mais_alta_d}></input>
                     Patela Mais Alta Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('patela_mais_alta_e', this.state.evaluation.patela_mais_alta_e)} defaultChecked={this.state.evaluation.patela_mais_alta_e}></input>
                     Patela Mais Alta Esquerda
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('patela_rodada_lateral', this.state.evaluation.patela_rodada_lateral)} defaultChecked={this.state.evaluation.patela_rodada_lateral}></input>
                     Patela Rodada Lateral
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('patela_rodada_medial', this.state.evaluation.patela_rodada_medial)} defaultChecked={this.state.evaluation.patela_rodada_medial}></input>
                     Patela Rodada Medial
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Cristas Ilíacas:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('crista_iliaca_alinhada', this.state.evaluation.crista_iliaca_alinhada)} defaultChecked={this.state.evaluation.crista_iliaca_alinhada}></input>
                     Alinhadas
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('crista_iliaca_desalinhada_d', this.state.evaluation.crista_iliaca_desalinhada_d)} defaultChecked={this.state.evaluation.crista_iliaca_desalinhada_d}></input>
                     Desalinhada Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('crista_iliaca_desalinhada_e', this.state.evaluation.crista_iliaca_desalinhada_e)} defaultChecked={this.state.evaluation.crista_iliaca_desalinhada_e}></input>
                     Desalinhada Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Alinhamento do Tronco:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_alinhado_va', this.state.evaluation.alinhamento_tronco_alinhado_va)} defaultChecked={this.state.evaluation.alinhamento_tronco_alinhado_va}></input>
                     Alinhado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_rotacao_cintura_escapular_va', this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapular_va)} defaultChecked={this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapular_va}></input>
                     Rotação da Cintura Escapular
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_rotacao_cintura_pelvica_va', this.state.evaluation.alinhamento_tronco_rotacao_cintura_pelvica_va)} defaultChecked={this.state.evaluation.alinhamento_tronco_rotacao_cintura_pelvica_va}></input>
                     Rotação da Cintura Pélvica
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_rotacao_cintura_escapularPelvica_va', this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapularPelvica_va)} defaultChecked={this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapularPelvica_va}></input>
                     Rotação da Cintura Escapular e Pélvica
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_inclinacao', this.state.evaluation.alinhamento_tronco_inclinacao)} defaultChecked={this.state.evaluation.alinhamento_tronco_inclinacao}></input>
                     Inclinação
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Tórax:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('torax_simetrico', this.state.evaluation.torax_simetrico)} defaultChecked={this.state.evaluation.torax_simetrico}></input>
                     Simétrico
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('torax_assimetrico', this.state.evaluation.torax_assimetrico)} defaultChecked={this.state.evaluation.torax_assimetrico}></input>
                     Assimétrico
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Cabeça:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_alinhada_va', this.state.evaluation.cabeca_alinhada_va)} defaultChecked={this.state.evaluation.cabeca_alinhada_va}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_inclinada_lateral_d_va', this.state.evaluation.cabeca_inclinada_lateral_d_va)} defaultChecked={this.state.evaluation.cabeca_inclinada_lateral_d_va}></input>
                     Inclinada Lateral Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_inclinada_lateral_e_va', this.state.evaluation.cabeca_inclinada_lateral_e_va)} defaultChecked={this.state.evaluation.cabeca_inclinada_lateral_e_va}></input>
                     Inclinada Lateral Esquerda
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_rotacao_d_va', this.state.evaluation.cabeca_rotacao_d_va)} defaultChecked={this.state.evaluation.cabeca_rotacao_d_va}></input>
                     Rotação Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_rotacao_e_va', this.state.evaluation.cabeca_rotacao_e_va)} defaultChecked={this.state.evaluation.cabeca_rotacao_e_va}></input>
                     Rotação Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Ombros:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_alinhado_va', this.state.evaluation.ombro_alinhado_va)} defaultChecked={this.state.evaluation.ombro_alinhado_va}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_mais_alto_d_va', this.state.evaluation.ombro_mais_alto_d_va)} defaultChecked={this.state.evaluation.ombro_mais_alto_d_va}></input>
                     Ombro Mais Alto Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_mais_alto_e_va', this.state.evaluation.ombro_mais_alto_e_va)} defaultChecked={this.state.evaluation.ombro_mais_alto_e_va}></input>
                     Ombro Mais Alto Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_medial_d_va', this.state.evaluation.ombro_rotacao_medial_d_va)} defaultChecked={this.state.evaluation.ombro_rotacao_medial_d_va}></input>
                     Rotação Medial Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_medial_e_va', this.state.evaluation.ombro_rotacao_medial_e_va)} defaultChecked={this.state.evaluation.ombro_rotacao_medial_e_va}></input>
                     Rotação Medial Esquerda
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_lateral_d_va', this.state.evaluation.ombro_rotacao_lateral_d_va)} defaultChecked={this.state.evaluation.ombro_rotacao_lateral_d_va}></input>
                     Rotação Lateral Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_lateral_e_va', this.state.evaluation.ombro_rotacao_lateral_e_va)} defaultChecked={this.state.evaluation.ombro_rotacao_lateral_e_va}></input>
                     Rotação Lateral Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Cotovelos:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cotovelo_alinhado', this.state.evaluation.cotovelo_alinhado)} defaultChecked={this.state.evaluation.cotovelo_alinhado}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cotovelo_aumento_flexao', this.state.evaluation.cotovelo_aumento_flexao)} defaultChecked={this.state.evaluation.cotovelo_aumento_flexao}></input>
                     Aumento de Flexão
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cotovelo_hiperextensao', this.state.evaluation.cotovelo_hiperextensao)} defaultChecked={this.state.evaluation.cotovelo_hiperextensao}></input>
                     Hiperextensão
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Clavículas:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('clavicula_simetrica', this.state.evaluation.clavicula_simetrica)} defaultChecked={this.state.evaluation.clavicula_simetrica}></input>
                     Simétricas
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('clavicula_horizontalizada_d', this.state.evaluation.clavicula_horizontalizada_d)} defaultChecked={this.state.evaluation.clavicula_horizontalizada_d}></input>
                     Horizontalizada Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('clavicula_horizontalizada_e', this.state.evaluation.clavicula_horizontalizada_e)} defaultChecked={this.state.evaluation.clavicula_horizontalizada_e}></input>
                     Horizontalizada Esquerda
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('clavicula_verticalizada_d', this.state.evaluation.clavicula_verticalizada_d)} defaultChecked={this.state.evaluation.clavicula_verticalizada_d}></input>
                     Verticalizada Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('clavicula_verticalizada_e', this.state.evaluation.clavicula_verticalizada_e)} defaultChecked={this.state.evaluation.clavicula_verticalizada_e}></input>
                     Verticalizada Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Triângulo de Thales:</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('triangulo_tales_simetrico', this.state.evaluation.triangulo_tales_simetrico)} defaultChecked={this.state.evaluation.triangulo_tales_simetrico}></input>
                     Simétricos
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('triangulo_tales_assimetrico_maior_d', this.state.evaluation.triangulo_tales_assimetrico_maior_d)} defaultChecked={this.state.evaluation.triangulo_tales_assimetrico_maior_d}></input>
                     Assimétrico Maior Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('triangulo_tales_assimetrico_maior_e', this.state.evaluation.triangulo_tales_assimetrico_maior_e)} defaultChecked={this.state.evaluation.triangulo_tales_assimetrico_maior_e}></input>
                     Assimétrico Maior Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-12 mt-3">
                <h5>Vista Lateral</h5>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Ângulo Tíbio-társico: Tornozelo</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_tibio_tarsico_preservado', this.state.evaluation.angulo_tibio_tarsico_preservado)} defaultChecked={this.state.evaluation.angulo_tibio_tarsico_preservado}></input>
                     Preservado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_tibio_tarsico_aumentado_d', this.state.evaluation.angulo_tibio_tarsico_aumentado_d)} defaultChecked={this.state.evaluation.angulo_tibio_tarsico_aumentado_d}></input>
                     Aumentado Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_tibio_tarsico_aumentado_e', this.state.evaluation.angulo_tibio_tarsico_aumentado_e)} defaultChecked={this.state.evaluation.angulo_tibio_tarsico_aumentado_e}></input>
                     Aumentado Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_tibio_tarsico_diminuido_d', this.state.evaluation.angulo_tibio_tarsico_diminuido_d)} defaultChecked={this.state.evaluation.angulo_tibio_tarsico_diminuido_d}></input>
                     Diminuido Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_tibio_tarsico_diminuido_e', this.state.evaluation.angulo_tibio_tarsico_diminuido_e)} defaultChecked={this.state.evaluation.angulo_tibio_tarsico_diminuido_e}></input>
                     Diminuido Esquerdo
                  </label>                  
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Articulação do Joelho</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_alinhada_vl', this.state.evaluation.articulacao_joelho_alinhada_vl)} defaultChecked={this.state.evaluation.articulacao_joelho_alinhada_vl}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_fletida_d', this.state.evaluation.articulacao_joelho_fletida_d)} defaultChecked={this.state.evaluation.articulacao_joelho_fletida_d}></input>
                     Fletida Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_fletida_e', this.state.evaluation.articulacao_joelho_fletida_e)} defaultChecked={this.state.evaluation.articulacao_joelho_fletida_e}></input>
                     Fletida Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_estendida_d', this.state.evaluation.articulacao_joelho_estendida_d)} defaultChecked={this.state.evaluation.articulacao_joelho_estendida_d}></input>
                     Estendida Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_joelho_estendida_e', this.state.evaluation.articulacao_joelho_estendida_e)} defaultChecked={this.state.evaluation.articulacao_joelho_estendida_e}></input>
                     Estendida Esquerdo
                  </label>                  
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Pelve</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('pelve_alinhada', this.state.evaluation.pelve_alinhada)} defaultChecked={this.state.evaluation.pelve_alinhada}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('pelve_retroversao', this.state.evaluation.pelve_retroversao)} defaultChecked={this.state.evaluation.pelve_retroversao}></input>
                     Retroversão
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('pelve_anteversao', this.state.evaluation.pelve_anteversao)} defaultChecked={this.state.evaluation.pelve_anteversao}></input>
                     Anteversão
                  </label>                 
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Alinhamento do Tronco</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_alinhado_vl', this.state.evaluation.alinhamento_tronco_alinhado_vl)} defaultChecked={this.state.evaluation.alinhamento_tronco_alinhado_vl}></input>
                     Alinhado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_rotacao_cintura_escapular_vl', this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapular_vl)} defaultChecked={this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapular_vl}></input>
                     Rotação de Cintura Escapular
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_rotacao_cintura_pelvica_vl', this.state.evaluation.alinhamento_tronco_rotacao_cintura_pelvica_vl)} defaultChecked={this.state.evaluation.alinhamento_tronco_rotacao_cintura_pelvica_vl}></input>
                     Rotação de Cintura Pélvica
                  </label>                  
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('alinhamento_tronco_rotacao_cintura_escapular_pelvica_vl', this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapular_pelvica_vl)} defaultChecked={this.state.evaluation.alinhamento_tronco_rotacao_cintura_escapular_pelvica_vl}></input>
                     Rotação de Cintura Escapular e Pélvica
                  </label>                
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Cabeça</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_normal', this.state.evaluation.cabeca_normal)} defaultChecked={this.state.evaluation.cabeca_normal}></input>
                     Normal
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_projetada_frente', this.state.evaluation.cabeca_projetada_frente)} defaultChecked={this.state.evaluation.cabeca_projetada_frente}></input>
                     Projetada pra Frente
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_projetada_tras', this.state.evaluation.cabeca_projetada_tras)} defaultChecked={this.state.evaluation.cabeca_projetada_tras}></input>
                     Projetada pra Trás
                  </label>              
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Ombros</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_alinhado_vl', this.state.evaluation.ombro_alinhado_vl)} defaultChecked={this.state.evaluation.ombro_alinhado_vl}></input>
                     Alinhado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_protuso', this.state.evaluation.ombro_protuso)} defaultChecked={this.state.evaluation.ombro_protuso}></input>
                     Protuso
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_retraido', this.state.evaluation.ombro_retraido)} defaultChecked={this.state.evaluation.ombro_retraido}></input>
                     Retraído
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_lateral_d_vl', this.state.evaluation.ombro_rotacao_lateral_d_vl)} defaultChecked={this.state.evaluation.ombro_rotacao_lateral_d_vl}></input>
                     Rotação Lateral Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_lateral_e_vl', this.state.evaluation.ombro_rotacao_lateral_e_vl)} defaultChecked={this.state.evaluation.ombro_rotacao_lateral_e_vl}></input>
                     Rotação Lateral Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_medial_d_vl', this.state.evaluation.ombro_rotacao_medial_d_vl)} defaultChecked={this.state.evaluation.ombro_rotacao_medial_d_vl}></input>
                     Rotação Medial Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_rotacao_medial_e_vl', this.state.evaluation.ombro_rotacao_medial_e_vl)} defaultChecked={this.state.evaluation.ombro_rotacao_medial_e_vl}></input>
                     Rotação Medial Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Articulação do Cotovelo</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_cotovelo_alinhada', this.state.evaluation.articulacao_cotovelo_alinhada)} defaultChecked={this.state.evaluation.articulacao_cotovelo_alinhada}></input>
                     Alinhado
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_cotovelo_flexao', this.state.evaluation.articulacao_cotovelo_flexao)} defaultChecked={this.state.evaluation.articulacao_cotovelo_flexao}></input>
                     Com Flexão de Cotovelo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_cotovelo_hiperextensao', this.state.evaluation.articulacao_cotovelo_hiperextensao)} defaultChecked={this.state.evaluation.articulacao_cotovelo_hiperextensao}></input>
                     Com Hiperextensão de Cotovelo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Coluna Cervical</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_cervical_normal', this.state.evaluation.coluna_cervical_normal)} defaultChecked={this.state.evaluation.coluna_cervical_normal}></input>
                     Normal
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_cervical_hiperlordose', this.state.evaluation.coluna_cervical_hiperlordose)} defaultChecked={this.state.evaluation.coluna_cervical_hiperlordose}></input>
                     Hiperlordose
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_cervical_retificada', this.state.evaluation.coluna_cervical_retificada)} defaultChecked={this.state.evaluation.coluna_cervical_retificada}></input>
                     Retificada
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Coluna Torácica</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_toracica_alinhada_vl', this.state.evaluation.coluna_toracica_alinhada_vl)} defaultChecked={this.state.evaluation.coluna_toracica_alinhada_vl}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_toracica_hipercifose', this.state.evaluation.coluna_toracica_hipercifose)} defaultChecked={this.state.evaluation.coluna_toracica_hipercifose}></input>
                     Hipercifose
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_toracica_retificada', this.state.evaluation.coluna_toracica_retificada)} defaultChecked={this.state.evaluation.coluna_toracica_retificada}></input>
                     Retificada
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Coluna Lombar</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_lombar_curvatura_normal', this.state.evaluation.coluna_lombar_curvatura_normal)} defaultChecked={this.state.evaluation.coluna_lombar_curvatura_normal}></input>
                     Curvatura Normal
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_lombar_hiperlordose', this.state.evaluation.coluna_lombar_hiperlordose)} defaultChecked={this.state.evaluation.coluna_lombar_hiperlordose}></input>
                     Hiperlordose
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_lombar_retificada', this.state.evaluation.coluna_lombar_retificada)} defaultChecked={this.state.evaluation.coluna_lombar_retificada}></input>
                     Retificada
                  </label>
                </div>
              </div>

              <div className="col-12 mt-3">
                <h5>Vista Posterior</h5>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Articulação dos Tornozelos</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_tornozelo_alinhada', this.state.evaluation.articulacao_tornozelo_alinhada)} defaultChecked={this.state.evaluation.articulacao_tornozelo_alinhada}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_tornozelo_valgo', this.state.evaluation.articulacao_tornozelo_valgo)} defaultChecked={this.state.evaluation.articulacao_tornozelo_valgo}></input>
                     Valgo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('articulacao_tornozelo_varo', this.state.evaluation.articulacao_tornozelo_varo)} defaultChecked={this.state.evaluation.articulacao_tornozelo_varo}></input>
                     Varo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Tendão de Aquiles</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('tendao_aquiles_alinhado', this.state.evaluation.tendao_aquiles_alinhado)} defaultChecked={this.state.evaluation.tendao_aquiles_alinhado}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('tendao_aquiles_desalinhado', this.state.evaluation.tendao_aquiles_desalinhado)} defaultChecked={this.state.evaluation.tendao_aquiles_desalinhado}></input>
                     Desalinhado
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Joelhos</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('joelho_alinhado', this.state.evaluation.joelho_alinhado)} defaultChecked={this.state.evaluation.joelho_alinhado}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('joelho_valgo', this.state.evaluation.joelho_valgo)} defaultChecked={this.state.evaluation.joelho_valgo}></input>
                     Valgo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('joelho_varo', this.state.evaluation.joelho_varo)} defaultChecked={this.state.evaluation.joelho_varo}></input>
                     Varo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Altura das Cristas Iliacas</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('altura_crista_iliaca_alinhada', this.state.evaluation.altura_crista_iliaca_alinhada)} defaultChecked={this.state.evaluation.altura_crista_iliaca_alinhada}></input>
                     Alinhadas
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('altura_crista_iliaca_desalinhada_mais_alta_d', this.state.evaluation.altura_crista_iliaca_desalinhada_mais_alta_d)} defaultChecked={this.state.evaluation.altura_crista_iliaca_desalinhada_mais_alta_d}></input>
                     Desalinhadas Mais Alta Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('altura_crista_iliaca_desalinhada_mais_alta_e', this.state.evaluation.altura_crista_iliaca_desalinhada_mais_alta_e)} defaultChecked={this.state.evaluation.altura_crista_iliaca_desalinhada_mais_alta_e}></input>
                     Desalinhadas Mais Alta Direita
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Coluna Lombar</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_lombar_alinhada', this.state.evaluation.coluna_lombar_alinhada)} defaultChecked={this.state.evaluation.coluna_lombar_alinhada}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_lombar_convexidade_d', this.state.evaluation.coluna_lombar_convexidade_d)} defaultChecked={this.state.evaluation.coluna_lombar_convexidade_d}></input>
                     Convexidade Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_lombar_convexidade_e', this.state.evaluation.coluna_lombar_convexidade_e)} defaultChecked={this.state.evaluation.coluna_lombar_convexidade_e}></input>
                     Convexidade Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Coluna Torácica</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_toracica_alinhada_vp', this.state.evaluation.coluna_toracica_alinhada_vp)} defaultChecked={this.state.evaluation.coluna_toracica_alinhada_vp}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_toracica_convexidade_d', this.state.evaluation.coluna_toracica_convexidade_d)} defaultChecked={this.state.evaluation.coluna_toracica_convexidade_d}></input>
                     Convexidade Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_toracica_convexidade_e', this.state.evaluation.coluna_toracica_convexidade_e)} defaultChecked={this.state.evaluation.coluna_toracica_convexidade_e}></input>
                     Convexidade Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Ângulo Inferior da Escápula</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_inferior_escapula_alinhado', this.state.evaluation.angulo_inferior_escapula_alinhado)} defaultChecked={this.state.evaluation.angulo_inferior_escapula_alinhado}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_inferior_escapula_desalinhado_mais_alto_d', this.state.evaluation.angulo_inferior_escapula_desalinhado_mais_alto_d)} defaultChecked={this.state.evaluation.angulo_inferior_escapula_desalinhado_mais_alto_d}></input>
                     Desalinhados Mais Alto Direita
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('angulo_inferior_escapula_desalinhado_mais_alto_e', this.state.evaluation.angulo_inferior_escapula_desalinhado_mais_alto_e)} defaultChecked={this.state.evaluation.angulo_inferior_escapula_desalinhado_mais_alto_e}></input>
                     Desalinhados Mais Alto Esquerda
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Escápulas</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('escapula_alinhada', this.state.evaluation.escapula_alinhada)} defaultChecked={this.state.evaluation.escapula_alinhada}></input>
                     Alinhadas
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('escapula_alada', this.state.evaluation.escapula_alada)} defaultChecked={this.state.evaluation.escapula_alada}></input>
                     Alada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('escapula_abduzida', this.state.evaluation.escapula_abduzida)} defaultChecked={this.state.evaluation.escapula_abduzida}></input>
                     Abduzidas
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('escapula_aduzida', this.state.evaluation.escapula_aduzida)} defaultChecked={this.state.evaluation.escapula_aduzida}></input>
                     Aduzidas
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Triângulo de Tales</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('triangulo_tales_simetrico_vp', this.state.evaluation.triangulo_tales_simetrico_vp)} defaultChecked={this.state.evaluation.triangulo_tales_simetrico_vp}></input>
                     Simétricos
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('triangulo_tales_maior_d', this.state.evaluation.triangulo_tales_maior_d)} defaultChecked={this.state.evaluation.triangulo_tales_maior_d}></input>
                     Maior Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('triangulo_tales_maior_e', this.state.evaluation.triangulo_tales_maior_e)} defaultChecked={this.state.evaluation.triangulo_tales_maior_e}></input>
                     Maior Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Ombros</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_alinhado', this.state.evaluation.ombro_alinhado)} defaultChecked={this.state.evaluation.ombro_alinhado}></input>
                     Alinhados
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_mais_alto_d_vp', this.state.evaluation.ombro_mais_alto_d_vp)} defaultChecked={this.state.evaluation.ombro_mais_alto_d_vp}></input>
                     Ombro Mais Alto Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('ombro_mais_alto_e_vp', this.state.evaluation.ombro_mais_alto_e_vp)} defaultChecked={this.state.evaluation.ombro_mais_alto_e_vp}></input>
                     Ombro Mais Alto Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Coluna Cervical</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_cervical_alinhada', this.state.evaluation.coluna_cervical_alinhada)} defaultChecked={this.state.evaluation.coluna_cervical_alinhada}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_cervical_convexidade_d', this.state.evaluation.coluna_cervical_convexidade_d)} defaultChecked={this.state.evaluation.coluna_cervical_convexidade_d}></input>
                     Convexidade Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('coluna_cervical_convexidade_e', this.state.evaluation.coluna_cervical_convexidade_e)} defaultChecked={this.state.evaluation.coluna_cervical_convexidade_e}></input>
                     Convexidade Esquerdo
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Cabeça</label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_alinhada_vp', this.state.evaluation.cabeca_alinhada_vp)} defaultChecked={this.state.evaluation.cabeca_alinhada_vp}></input>
                     Alinhada
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_inclinacao_lateral_d_vp', this.state.evaluation.cabeca_inclinacao_lateral_d_vp)} defaultChecked={this.state.evaluation.cabeca_inclinacao_lateral_d_vp}></input>
                     Inclinação Lateral Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_inclinacao_lateral_e_vp', this.state.evaluation.cabeca_inclinacao_lateral_e_vp)} defaultChecked={this.state.evaluation.cabeca_inclinacao_lateral_e_vp}></input>
                     Inclinação Lateral Esquerdo
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_rotacao_d_vp', this.state.evaluation.cabeca_rotacao_d_vp)} defaultChecked={this.state.evaluation.cabeca_rotacao_d_vp}></input>
                     Rotação Direito
                  </label>
                  <label>
                    <input type="checkbox" onChange={() => this.handleCheckboxChange('cabeca_rotacao_e_vp', this.state.evaluation.cabeca_rotacao_e_vp)} defaultChecked={this.state.evaluation.cabeca_rotacao_e_vp}></input>
                     Rotação Esquerdo
                  </label>
                </div>
              </div>

              <div className="ml-auto col-12 mt-3">
                <div className="form-group">
                  <SubmitButton />
                </div>
              </div>
            </div>

          </form> : null
        }
        
      </div>
    )    
  }
}

export default EvaluationDetail;