import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './../modules/App/App';
import Login from './../modules/Login/Login';
import PrivateRoute from './privateRoute';
import ProductionLine from '../modules/ProductionLine/components/ProductionLine';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/" exact={true} component={App} />
      <PrivateRoute path="/privado" component={App} />
      <Route path="/login" component={Login} />
      <Route path="/redefinir-senha/:token" component={Login} />
      <PrivateRoute path="/producao" component={ProductionLine} />
    </Switch>
  </BrowserRouter>
);

export default Routes;