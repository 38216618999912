import React from 'react';
import './Login.scss';
import axios from 'axios';
import ErrorMessage from '../shared/errorMessage/errorMessage';
import Loading from '../shared/loading/loading';
import { Link } from 'react-router-dom';


class ResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      isUpdated: false,
      hasError: false,
      isLoading: false,
      token: ''
    }
  }

  componentDidMount = () => {
    this.setState({ token: this.props.token })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      hasError: false,
      isUpdated: false
    });

    const options = {
      method: 'POST',
      url: '/user/set-new-password',
      data: {
        reset_key: this.state.token,
        new_password: this.state.password
      },
      json: true
    };

    axios(options)
      .then(response => {
        this.setState({
          isUpdated: true,
          isLoading: false
        });
      })
      .catch(error => {
        console.log('error:' + error);
        this.setState({
          hasError: true,
          isLoading: false
        });
      })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render() {
    const { password, confirmPassword } = this.state;
    const enabled = password && (password === confirmPassword);
    return (
      <form onSubmit={this.handleSubmit}>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="header text-center">
          <h4 className="title">Redefinir senha</h4>
        </div>
        {
          this.state.hasError ? <div className="card-has-error"><ErrorMessage message="Não foi possível redefinir a senha. Tente novamente." /></div> : null
        }
        {
          this.state.isUpdated ? <div className="card-has-error"><ErrorMessage type="success" message="Senha redefinida com sucesso." /></div> : null
        }
        <div className="form-group">
          <label>Senha</label>
          <input className="form-control" placeholder="Digite a nova senha" type="password" name="password" value={this.state.password} onChange={this.handleInputChange}></input>
        </div>
        <div className="form-group">
          <label>Confirmação de Senha</label>
          <input className="form-control" placeholder="Confirme a senha" type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange}></input>
        </div>
        <button className="btn btn-info btn-wd" disabled={!enabled} type="submit">Salvar</button>
        <Link className="back-link" to={'/'}><i title="voltar" className="fa fa-arrow-left"></i> Voltar para o login</Link>
      </form>
    )
  }
}

export default ResetForm;