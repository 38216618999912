import React from 'react';
import './Login.scss';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import ErrorMessage from '../shared/errorMessage/errorMessage';
import Loading from '../shared/loading/loading';


class ForgetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSent: false,
      hasError: false,
      isLoading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
      hasError: false,
      isSent: false
    });

    const options = {
      method: 'POST',
      url: '/user/reset-password',
      data: {
        email: this.state.email
      },
      json: true
    };

    axios(options)
      .then(response => {
        this.setState({
          isSent: true,
          isLoading: false
        });
      })
      .catch(error => {
        console.log('error:' + error);
        this.setState({
          hasError: true,
          isLoading: false
        });
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render() {
    if(this.state.isAuthenticated) {
      return <Redirect to='/' />
    }
    const { email } = this.state;
    const enabled = email.length > 0;
    return (
      <form onSubmit={this.handleSubmit}>
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="header text-center">
          <h4 className="title">Esqueci minha senha</h4>
        </div>
        {
          this.state.hasError ? <div className="card-has-error"><ErrorMessage message="Não foi possível fazer a recuperação de senha. Confira o email e tente novamente." /></div> : null
        }
        {
          this.state.isSent ? <div className="card-has-error"><ErrorMessage type="success" message="Enviamos para seu e-mail o link de redefinição de senha." /></div> : null
        }
        <div className="form-group">
          <label>Email</label>
          <input className="form-control" placeholder="Digite o email" type="text" name="email" value={this.state.email} onChange={this.handleInputChange}></input>
        </div>
        <button className="btn btn-info btn-wd" disabled={!enabled} type="submit">Recuperar senha</button>
        <div className="back-link" onClick={this.props.backToLogin} ><i title="voltar" className="fa fa-arrow-left"></i> Voltar para o login</div>
      </form>
    )
  }
}

export default ForgetForm;