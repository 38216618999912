import React from 'react';
import './brand.scss';

const Brand = () => {
  return (
    <div className="logo">
      <div className="dots">
        <span>
          <svg viewBox="0 0 200 200">
            <circle className="one" cx="100" cy="100" r="75" />
          </svg>
        </span>
        <span>
          <svg viewBox="0 0 200 200">
            <circle className="two" cx="100" cy="100" r="75" />
          </svg>
        </span>
        <span>
          <svg viewBox="0 0 200 200">
            <circle className="three" cx="100" cy="100" r="75" />
          </svg>
        </span>
      </div>
      finapp      
    </div>
  )
}

export default Brand;