import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './ProductionLine.scss'; 
import ProductionCard from './productionCard';
import 'reactjs-toastr/lib/toast.css';

import { messaging } from './../../../init-fcm';

import Loading from './../../shared/loading/loading';

class ProductionLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      performedServices: [],
      filteredServices: [],
      producers: [],
      activePage: 1,
      totalCount: 0,
      isLoading: false,
      selectedProducer: ''
    }
  }

  componentDidMount() {
    this.getPerformedServices(1);
    this.getProducers();

    if ("serviceWorker" in navigator && messaging) {
      messaging.requestPermission()
      .then(async function() {
        const token = await messaging.getToken();
        
        console.log(token);

        const options = {
          method: 'POST',
          url: '/user',
          data: {fcm_user_token: token, _METHOD: 'PUT'},
          json: true
        };
        
        axios(options).then(() => {}).catch(error => console.log(error));

      })
      .catch(function(err) {
        console.log('Unable to get permission to notify.', err);
      });

      navigator.serviceWorker.addEventListener('message', (message) => this.getPerformedServices(1));
      messaging.onMessage((payload) => console.log('Message received. ', payload));

    }
  }


  getPerformedServices = (page) => {
    this.setState({ isLoading: true });

    const data = {
      quantity: process.env.REACT_APP_PAGE_SIZE,
      page,
      get_detail: true,
      sort_column: 'isDone',
      sort_direction: 'asc'
    }

    const options = {
      method: 'POST',
      url: '/performed-services/list',
      data,
      json: true
    };
    
    axios(options).then(response => {
      this.setState({ 
        performedServices : response.data.data,
        totalCount: response.data.pagination.total_count,
        isLoading: false
      }, () => this.filterServices());
    }).catch(error => {
      this.setState({ 
        performedServices : [],
        totalCount: 0,
        isLoading: false
      });
    });
  }

  getProducers = () => {
    
    const options = {
      method: 'POST',
      url: '/services/list/producers',
      data: {},
      json: true
    };
    
    axios(options).then(response => {
      this.setState({ 
        producers : response.data.data
      });
    }).catch(error => {
      this.setState({ 
        producers : []
      });
    });
  }

  filterServices() {
    let services = this.state.performedServices;
    this.setState({
      selectedProducer: localStorage.getItem('producer') ? localStorage.getItem('producer') : ''
    }, () => {
      if (this.state.selectedProducer === 'Todos') {
        this.setState({
          filteredServices: services
        })
      }
      if (this.state.selectedProducer && this.state.selectedProducer !== 'Todos') {
        if (services.length) {
          services = services.filter(el => el.service_producer === this.state.selectedProducer);
          this.setState({
            filteredServices: services
          })
        }
      } else {
        this.setState({
          filteredServices: services
        })        
      }
    })
  }

  handleProducers = (event) => {
    this.setState({
      selectedProducer: event.target.value
    }, () => {
      localStorage.setItem('producer', this.state.selectedProducer);
      this.filterServices();
    });    
  }

  changeStatus = (status, serviceId) => {
    this.setState({ isLoading: true });

    const options = {
      method: 'POST',
      url: `/performed-services/${serviceId}`,
      data: {
        is_done: status,
        _METHOD: 'PUT'
      },
      json: true
    };
    
    axios(options).then(response => {

      this.getPerformedServices(1);

    }).catch(error => {
      this.setState({ 
        isLoading: false
      });
    });

  }

  render() {
    return (
      <div className="production-line-page">
        {
          this.state.isLoading ? <Loading /> : null
        }
        <div className="container-fluid ps-page">
          <div className="row">
            <div className="col-4">
              <Link className="back" to={'/'}><i title="voltar" className="fa fa-arrow-left"></i> Voltar para o sistema</Link>
            </div>
            <div className="col-4">
              <h4 className="title">Produção</h4>
            </div>
            <div className="col-4">
              <div className="filters">
                <select value={this.state.selectedProducer} onChange={(event) => this.handleProducers(event)}>
                  <option value="" disabled>Produzido por</option>
                  <option value="Todos">Todos</option>
                  {
                    this.state.producers.map(producer => <option key={producer} value={producer}>{producer}</option>)
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="perfomed-services-container">
          <div className="row row-eq-height">
            {
              this.state.filteredServices.map(item => <ProductionCard key={item.id} service={item} handleChange={(status, id) => this.changeStatus(status, id)} /> )
            }
          </div>   
        </div>
      </div>
    )
  }
}

export default ProductionLine;